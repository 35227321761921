<template>
  <artmisads-modal class="edit-deal-modal" v-model:open="editDealInfoRef.open" :width="720"
    :title="editDealInfoRef.id ? 'Edit Deals' : 'Add New Deal'">
    <a-spin :spinning="saveDealLoading">
      <a-form ref="formRef" layout="vertical" :model="editDealInfoRef">
        <a-form-item name="dateRange">
          <div class="input-item">
            <span class="text">Date Range</span>
            <artemisads-range-picker v-model:value="editDealInfoRef.dateRange" :placeholder="['Start Date', 'End Date']"
              :disabled="[!!editDealInfoRef.id && editDealInfoRef.status !== 'Upcoming', false]"
              :disabled-date="disabledDate" />
          </div>
        </a-form-item>
        <a-form-item name="fullPrice" v-bind="validateInfos.fullPrice">
          <div class="input-item">
            <span class="text">Full Price<span>*</span></span>
            <artmisads-input type="number" size="middle" v-model:value="editDealInfoRef.fullPrice" @blur="handleInput"
              formItem="fullPrice" :prefix="currencySymbol" placeholder="..." />
          </div>
        </a-form-item>
        <a-form-item name="finalPrice" v-bind="validateInfos.finalPrice">
          <div class="input-item">
            <span class="text">Final Price<span>*</span></span>
            <artmisads-input type="number" size="middle" v-model:value="editDealInfoRef.finalPrice" @blur="handleInput"
              formItem="finalPrice" :prefix="currencySymbol" placeholder="..." />
          </div>
        </a-form-item>
        <a-form-item name="type" v-bind="validateInfos.type">
          <div class="input-item">
            <span class="text">Deal Type<span>*</span></span>
            <artmisads-select style="width: 100%;" v-model:value="editDealInfoRef.type" :disabled="editDealInfoRef.id"
              placeholder="Select...">
              <a-select-option value="Deal">Deal</a-select-option>
              <a-select-option value="Clippable Coupon">
                Clippable Coupon</a-select-option>
              <a-select-option value="Promo Code">Promo Code</a-select-option>
            </artmisads-select>
          </div>
        </a-form-item>
        <a-form-item name="promoCode" v-if="editDealInfoRef.type === 'Promo Code'" :rules="{
          required: true,
          message: 'Promo Code is empty',
        }">
          <div class="input-item">
            <span class="text">Promo Code<span>*</span></span>
            <artmisads-input @mounted="handlePromoCodeVisibile" ref="promoCodeRef" type="text" size="middle"
              v-model:value="editDealInfoRef.promoCode" />
          </div>
        </a-form-item>
      </a-form>
      <p class="notice">
        <i class="iconfont icon-info" />Please note that for changes to ongoing Deal activities, data up to
        and including the change date will be retained, and data after that
        will be updated.
      </p>
    </a-spin>
    <template #footer>
      <div :class="'flex-wrapper' + (editDealInfoRef.status !== 'Active' && editDealInfoRef.id ? ' show-delete' : '')">
        <div class="flex-left" v-if="editDealInfoRef.status !== 'Active' && editDealInfoRef.id"
          ref="deleteConfirmModal">
          <a-popconfirm class="delete-confirm-modal" :getPopupContainer="() => $refs.deleteConfirmModal"
            title="Are you sure you want to delete this deal?" @confirm="deleteDealInModal(editDealInfoRef.id)"
            ok-text="Yes" width="100" cancel-text="No">
            <a href="javascript:void(0)" class="delete"><i class="iconfont icon-lajitong" /> Delete</a>
          </a-popconfirm>
        </div>
        <div class="flex-right">
          <artmisads-button class="cancel" @click="hideDealEditModal">Cancel</artmisads-button>
          <artmisads-button @click="saveDealInModal" type="primary">Save</artmisads-button>
        </div>
      </div>
    </template>
  </artmisads-modal>
</template>

<script lang="jsx" setup>
import moment from 'moment';
import { ref, reactive, watch, inject } from 'vue';
import { productsApi } from '~/server/index';
import { message, Form } from 'ant-design-vue';
import { CURRENCY_MAPPER } from '@/contants/common'

const { productId, countryCode, editDealInfo } = defineProps(['productId', 'countryCode', 'editDealInfo']);
const editDealInfoRef = ref(editDealInfo);

const emits = defineEmits(['update:editDealInfo']);
const currencySymbol = ref(CURRENCY_MAPPER[countryCode || 'US'].currency)
const saveDealLoading = ref(false);
const promoCodeRef = ref(null);
const rulesRef = reactive({
  fullPrice: [{
    required: true,
    validator: (_rule, value) => {
      value = +value

      if (!value) {
        return Promise.reject('Full Price cannot be empty');
      } else if (value <= 0) {
        return Promise.reject('Full Price should be greater than 0');
      } else {
        return Promise.resolve();
      }
    },
    trigger: ['blur', 'change'],
  }],
  finalPrice: [{
    required: true,
    validator: (_rule, value) => {
      value = +value

      if (!value) {
        return Promise.reject('Final Price cannot be empty');
      } else if (value < 0) {
        return Promise.reject('Final Price cannot be less than 0');
      } else if (value > +editDealInfoRef.value.fullPrice) {
        return Promise.reject('Final Price should be less than Full Price');
      } else {
        return Promise.resolve();
      }
    },
    trigger: ['blur', 'change'],
  }],
  type: [{
    required: true,
    validator: (_rule, value) => {
      if (!value) {
        return Promise.reject('Deal type cannot be empty');
      } else {
        return Promise.resolve();
      }
    },
    trigger: ['blur', 'change'],
  }],
  promoCode: [{
    required: true,
    validator: (_rule, value) => {
      if (editDealInfoRef.value.type === 'Promo Code' && !value) {
        return Promise.reject('Promo Code is empty');
      } else {
        return Promise.resolve();
      }
    },
    trigger: ['blur', 'change'],
  }],
});
const formRef = ref();
const { validate, validateInfos, resetFields } = Form.useForm(editDealInfoRef, rulesRef);
const disabledDate = (current) => {
  return current && current < moment().endOf('day');
};
const saveDeal = inject('saveDeal');
const deleteDeal = inject('deleteDeal');

const handleInput = (e) => {
  const newValue = e.target.value.replace(/[^0-9.]/g, '')
  const type = e.target.getAttribute('formItem');

  console.log(`type: ${type}, newValue: ${newValue}`)
  editDealInfoRef.value[type] = Number(newValue)
}

function deleteDealInModal(dealId) {
  if (!saveDealLoading.value) {
    saveDealLoading.value = true;
    productsApi.deleteDeal(dealId).then((res) => {
      const { success, error } = res;

      saveDealLoading.value = false;

      if (success) {
        message.success('Delete success');
        editDealInfoRef.value.open = false;
        deleteDeal(editDealInfoRef.value.index)
      } else {
        message.error(error);
      }
    });
  }
}

function handleValidate(valid) {
  console.log('valid', valid);
}

// 保存Deal, 并更新列表
async function saveDealInModal() {
  validate().then((valid) => {
    if (valid && !saveDealLoading.value) {
      if (editDealInfoRef.value.type === 'Promo Code' && !editDealInfoRef.value.promoCode) {
        console.log('editDealInfoRef.value.promoCode', editDealInfoRef.value.promoCode);
        formRef.value?.validateFields(['promoCode'], (errors, values) => {
          // 不作处理, 表单直接显示错误信息
          if (errors) {
            console.log(validateInfos)
          }
        })

        return
      }

      const params = {
        productId,
        countryCode,
        ...editDealInfoRef.value,
        status: editDealInfoRef.value.status || 'Upcoming',
        beginTime: editDealInfoRef.value.dateRange[0]?.startOf('date').valueOf(),
        endTime: editDealInfoRef.value.dateRange[1]?.endOf('date').valueOf(),
      }

      for (const key in params) {
        if (
          !params[key] ||
          ['currencySymbol', 'dateRange', 'open', 'priceOff', 'index'].indexOf(key) != -1
        ) {
          delete params[key]
        }
      }

      saveDealLoading.value = true

      productsApi.saveDeal(params).then((res) => {
        const { success, error } = res

        saveDealLoading.value = false

        if (success) {
          message.success('Save success')
          editDealInfoRef.value.open = false
          saveDeal(editDealInfoRef.value.index, res.data)
        } else {
          if (res.code === '6510') {
            message.error('There is a deal of same type within this time period. Please re-edit.')
          } else {
            message.error(error)
          }
        }
      })
    }
  }).catch((err) => {
    formRef.value?.validateFields(['promoCode'], (errors, values) => {
      // 不作处理, 表单直接显示错误信息
      if (errors) {
        console.log(validateInfos)
      }
    })
    console.log(err)
  })
}

function hideDealEditModal() {
  emits('update:editDealInfo', {
    id: null,
    type: null,
    open: false,
    dateRange: [],
    fullPrice: '',
    finalPrice: '',
  })
}

function handlePromoCodeVisibile() {
  console.log('adsfdsfs0fds---------');
  promoCodeRef.value?.focus();
}

watch(() => editDealInfo, (newVal) => {
  editDealInfoRef.value = newVal
}, {
  immediate: true,
})

// 监听弹窗状态
watch(() => editDealInfoRef.value.open, (newValue) => {
  if (!newValue) { // 当弹窗关闭时
    resetFields();
  }
});
</script>

<style lang="less" scoped>
.edit-deal-modal {
  .ant-form {
    margin-right: 112px;
  }

  .ant-form-item {

    .input-item {
      display: flex;
      flex-direction: column;

      .text {
        color: var(--dark-2);
        font-weight: 500;
        line-height: 18px;
        font-size: 13px;
        margin-bottom: 4px;
        display: flex;

        >span {
          color: var(--red-text);
          margin-left: 3px;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
        }
      }

      .artmisads-input {
        :deep(.ant-input) {

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    }
  }

  .notice {
    font-size: 12px;
    padding-top: 16px;
    line-height: 16px;
    color: var(--dark-3);
    letter-spacing: 0.3px;

    >.iconfont {
      height: 14px;
      font-size: 12px;
      font-size: 14px;
      margin-right: 3px;
      vertical-align: top;
      display: inline-block;
    }
  }

  .artmisads-button:deep {
    height: 32px;
    padding: 0 30px;
    font-size: 14px;
    min-width: auto;
    line-height: 14px;
  }

  .ant-form-item-label {
    font-size: 14px;
    font-weight: 500;
  }

  .ant-picker {
    width: 272px;

    :deep(.ant-picker-input) {
      >input[disabled] {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .flex-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.show-delete {
      justify-content: space-between;
    }

    .ant-button {
      height: 32px;
    }

    :deep(.cancel) {
      border: none;
      background-color: var(--bg-gray-4);

      &:hover {
        background-color: var(--bg-gray-1);
      }
    }

    :deep(.ant-popover) {
      width: 240px;

      &-arrow-content:before {
        bottom: 0;
        width: 16px;
        height: 8px;
        position: absolute;
        inset-inline-start: 0;
        clip-path: polygon(1.6568542494923806px 100%,
            50% 1.6568542494923806px,
            14.34314575050762px 100%,
            1.6568542494923806px 100%);
        clip-path: path('M 0 8 A 4 4 0 0 0 2.82842712474619 6.82842712474619 L 6.585786437626905 3.0710678118654755 A 2 2 0 0 1 9.414213562373096 3.0710678118654755 L 13.17157287525381 6.82842712474619 A 4 4 0 0 0 16 8 Z'
          );
        content: '';
        background: #fff;
      }

      &-arrow-content:after {
        content: '';
        position: absolute;
        width: 8.970562748477143px;
        height: 8.970562748477143px;
        bottom: 0;
        inset-inline: 0;
        margin: auto;
        border-radius: 0 0 2px 0;
        transform: translateY(50%) rotate(-135deg);
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.1);
        z-index: 0;
        background: transparent;
      }

      &-inner {
        padding: 12px;
      }
    }


    .delete {
      display: flex;
      font-weight: 500;
      align-items: center;
      color: var(--red-text);

      &:hover {
        color: var(--red-text);
      }

      >.iconfont {
        height: auto;
        margin-right: 4px;
      }
    }
  }
}
</style>