<template>
  <div class="product">
    <div class="breadcrumbs">
      <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
        <svg class="icon" aria-hidden="true" style="fill: #fff">
          <use xlink:href="#icon-shangpin"></use>
        </svg>
      </breadcrumbs>
    </div>
    <a-spin :spinning="spinning">
      <div class="content-wrap">
        <pulic-report-notice type="urgent" @click="() => getProductVariations('active')"
          v-if="detailData.status === 'inactive'">
          <template #title>Product is not active</template>
          <template #action>Activate Product</template>
        </pulic-report-notice>
        <div class="content">
          <div class="content-top">
            <div class="left">
              <img :src="detailData.imageUrl" v-if="detailData.imageUrl" />
            </div>
            <div class="right">
              <div class="detail" ref="detailRef">
                <div class="title">
                  <div class="title-head">
                    <p>{{ detailData.title }}</p>
                    <div class="operation" v-if="detailData.status === 'active'">
                      <a-dropdown :trigger="['click']" :getPopupContainer="(triggerNode) => triggerNode.parentNode
                        ">
                        <artmisads-select-button>
                          <svg class="icon" aria-hidden="true" style="font-size: 20px; font-weight: 500">
                            <use xlink:href="#icon-gengduo"></use>
                          </svg>
                        </artmisads-select-button>
                        <template #overlay>
                          <a-menu>
                            <a-menu-item @click="getProductVariations('deactivate')"
                              style="white-space: nowrap; margin: 4px">
                              Deactivate Products
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                      <!-- <artmisads-select-button size="small">...</artmisads-select-button> -->
                    </div>
                  </div>
                  <a target="_blank" :href="detailData.productUrl">Amazon Detail Page
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-tiaozhuan"></use>
                    </svg>
                  </a>
                </div>
                <div class="items">
                  <div class="item">
                    <span>ASIN:</span>
                    <span>{{ detailData.asin }}</span>
                  </div>
                  <div class="item">
                    <span>Brand:</span>
                    <span><a :href="`/seller/brand/detail/${detailData.brandId}`" target="_blank">{{
                      detailData.brandName
                        }}</a></span>
                  </div>
                  <div class="item">
                    <span>Price:</span>
                    <price-deal-layout v-if="detailData.combineDeal" :record="detailData.combineDeal" />
                    <span v-else>{{ detailData.currencySymbol
                    }}{{ Number(detailData.price || 0).toFixed(2) }}</span>
                  </div>
                  <div class="item">
                    <span>Category:</span>
                    <span>{{ detailData.category }}</span>
                  </div>
                  <div class="item">
                    <span>Best Seller Rank:</span>
                    <span>{{ detailData.bestSellerRank }}</span>
                  </div>
                  <div class="item">
                    <span>Availiability:</span>
                    <span>{{
                      availiabilityMap[detailData.availiability] ||
                      'Out Of Stock'
                      }}</span>
                  </div>
                  <div class="item">
                    <span>Commission:</span>
                    <span class="edit-commission">
                      <artmisads-button :isSquare="true" type="primary" size="middle" class="commission" @click="
                        () => {
                          setSelectSingleProduct(detailData.id)
                          setShowSetCommission(true)
                        }
                      ">
                        <span class="text">{{ detailData.commission?.toFixed(1) }}%</span>
                        <EditOutlined />
                      </artmisads-button>
                      <a-button class="history" @click="
                        () => {
                          setSelectSingleProduct(detailData.id)
                          setShowCommissionHistory(true)
                        }
                      "><i class="iconfont icon-lishijilu" /></a-button>
                    </span>
                  </div>
                  <div class="item">
                    <span>Status:</span>
                    <span style="opacity: 1">
                      <showStatus :isActive="detailData.status === 'active'" />
                    </span>
                  </div>
                </div>
                <div class="title deals-section">
                  <div class="title-head">
                    <p class="sub-title">
                      Deals
                      <artmisads-select-button class="add-deal" @click="showDealEditModal">
                        <svg class="icon" aria-hidden="true" style="font-size: 20px; margin-right: 8px;">
                          <use xlink:href="#icon-jiahao"></use>
                        </svg>Add New Deal</artmisads-select-button>
                    </p>
                  </div>
                  <ProductDeals :dataSource="detailData.productDeals" :showEdit="true" @edit="editDeal" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="false" class="content-bottom">
            <div class="creators">
              <p>Creators</p>
              <div class="content-bottom-wrap">
                <div class="content">
                  <svg class="icon" aria-hidden="true" style="fill: rgba(15, 6, 41, 0.5)">
                    <use xlink:href="#icon-kongshuju"></use>
                  </svg>
                  <p>No Creators are promoting this product</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <setPublicCommission :type="'product-detail'" :showSetCommission="showSetCommission" :productList="detailData"
        :selectedRowKeys="[]" :setShowSetCommission="setShowSetCommission" :getProductList="getProductDetail"
        :selectSingleProduct="selectSingleProduct" :setSelectSingleProduct="setSelectSingleProduct"
        v-if="detailData.id" />
      <commissionHistory :setShowCommissionHistory="setShowCommissionHistory"
        :showCommissionHistory="showCommissionHistory" :productId="productId" v-if="detailData.id" />
      <contextHolder />
    </a-spin>
    <variations class="seller-product-variations" v-if="detailData.variationTotal" :id="route.params.id"
      userType="seller" :showStatus="true" :key="variationsKey" />
    <artmisads-modal v-model:open="showDeactivateModal" :title="`Are you sure you want to Deactivate this Product?`"
      :width="500" :destroyOnClose="true" :maskClosable="false">
      <div class="inactive-content">
        <p v-if="hasVariations">
          Deactivating a Product will archive the active Publisher/Creator links
          of the product and its related variations, and also make them
          unavailable to Publishers and Creators.
        </p>
        <p v-else>
          Deactivating a Product will archive all active Publisher/Creator
          links. and make this product unavailable to publishers and creators.
        </p>
      </div>
      <template #footer>
        <artmisads-button @click="() => setShowDeactivateModal(false)">Cancel</artmisads-button>
        <artmisads-button @click="() => onChangeStatus('inactive')" type="primary">Confirm</artmisads-button>
      </template>
    </artmisads-modal>
    <artmisads-modal v-model:open="showActiveModal" :width="500" title="Are you sure you want to Activate this Product?"
      okText="Confirm" @ok="() => onChangeStatus('active')" @cancel="setShowActivateModal(false)">
      <div class="active-product">
        <div class="title-ext">
          Activate the Product, and both the product itself and its related
          variations will be activated.
        </div>
      </div>
    </artmisads-modal>
    <dealEditModal v-if="editDealInfo.open" :editDealInfo="editDealInfo" :countryCode="detailData.countryCode"
      :productId="productId"  @update:editDealInfo="showDealEditModal" />
  </div>
</template>

<script lang="jsx" setup>
import moment from 'moment';
import { useRoute } from 'vue-router';
import { EditOutlined } from '@ant-design/icons-vue';
import { message, notification } from 'ant-design-vue';
import { computed, onMounted, ref, provide } from 'vue';

import showStatus from '../../Brands/components/showStatus.vue';
import PulicReportNotice from "@/components/PulicReportNotice.vue";
import { productsApi } from '../../../../server/index'
import breadcrumbs from '../../breadcrumbs.vue';
import setPublicCommission from '../setPublicCommission.vue';
import commissionHistory from './commissionHistory.vue';
import Variations from "~/components/variations.vue";
import ProductDeals from '~/components/ProductDeals.vue';
import PriceDealLayout from '~/components/PriceDealLayout';
import dealEditModal from './dealEditModal.vue';
const availiabilityMap = {
  IN_STOCK: 'In Stock',
  OUT_OF_STOCK: 'Out Of Stock'
}

const route = useRoute();
const hasVariations = ref(false);
const showCommissionHistory = ref(false);
const showActiveModal = ref(false);
const showDeactivateModal = ref(false);
const showSetCommission = ref(false);
const selectSingleProduct = ref('');
const detailRef = ref(null);
const spinning = ref(true);
const productId = ref('');
const editDealInfo = ref({
  id: null,
  type: null,
  dateRange: [],
  fullPrice: '',
  finalPrice: '',
});
const breadcrumbsData = computed(() => {
  const data = {
    title: 'Your Products',
    des: 'Review you active ASINs that will be listed on our marketplace for Creator discovery',
    color: '#4682FA',
    links: [
      {
        name: 'Home',
        path: '/seller/dashboard'
      },
      {
        name: 'Products',
        path: '/seller/product'
      },
      {
        name: 'Product Detail',
        path: `/seller/product/${productId.value}`
      },
    ]
  }
  return data;
})
const variationsKey = ref(1);
const detailData = ref({});
const [api, contextHolder] = notification.useNotification();
const openNotification = description => {
  api.info({
    message: `Notice`,
    description: description,
    placement: 'topRight',
    duration: 3
  });
};
const setShowDeactivateModal = status => {
  showDeactivateModal.value = status;
}
const setShowActivateModal = (status) => {
  showActiveModal.value = status;
}

const setShowCommissionHistory = status => {
  showCommissionHistory.value = status;
}
const setShowSetCommission = status => {
  showSetCommission.value = status;
}

const onChangeStatus = async status => {
  spinning.value = true;
  showDeactivateModal.value = false;
  showActiveModal.value = false;

  let res, params = [productId.value];

  if (status === 'active') {
    res = await productsApi.activeProduct(params);
  } else {
    res = await productsApi.inactiveProduct(params);
  }

  const { success, error, code } = res;

  if (success) {
    if (status === 'active') {
      message.success('Active');
    } else {
      message.success('Inactive');
    }
    detailData.value.status = status;
    variationsKey.value = !variationsKey.value;
  } else {
    if (code === '1014') {
      openNotification(error);
    }
  }
  spinning.value = false;
}

const getProductVariations = async (status) => {
  let hasVariation = false;
  let res = await productsApi.getProductVariations([productId.value]);

  if (res.data.activeProductVariationCount || res.data.inactiveProductVariationCount) {
    hasVariation = true;
  }

  if (status == 'active') {
    if (hasVariation) {
      setShowActivateModal(true);
    } else {
      onChangeStatus('active');
    }
  }

  if (status == 'deactivate') {
    hasVariations.value = hasVariation;

    setShowDeactivateModal(true);
  }
}
const getProductDetail = async id => {
  spinning.value = true;
  const res = await productsApi.getProductDetail(id);
  const { success, data } = res;

  if (success) {
    detailData.value = data;
  }

  spinning.value = false;
}

const setSelectSingleProduct = id => {
  selectSingleProduct.value = id;
}

function showDealEditModal(data) {
  editDealInfo.value = {
    id: null,
    type: null,
    open: true,
    dateRange: [],
    fullPrice: '',
    finalPrice: '',
    ...data
  }
}

// 保存Deal, 并更新列表
function saveDeal(index, deal) {
  if (index != undefined) {
    detailData.value.productDeals[index] = deal
  } else {
    detailData.value.productDeals.push(deal)
  }
}

// 编辑Deal
function editDeal(index, record) {
  editDealInfo.value = {
    ...record,
    open: true,
    index,
    dateRange: [moment(record.beginTime), moment(record.endTime)]
  };
}

function deleteDeal(index) {
  detailData.value.productDeals.splice(index, 1);
}

provide('saveDeal', saveDeal);
provide('deleteDeal', deleteDeal);

onMounted(() => {
  const { id } = route.params;
  if (id) {
    productId.value = id;
    getProductDetail(id);
  }
})

</script>

<style lang="less" scoped>
.product {
  padding: 0 16px 16px;
}

.content {
  display: flex;
  flex-direction: column;
}

.content-top {
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: var(--section-box-shadow-6);
}

.left {
  width: 256px;
  height: 256px;
  border-radius: 8px;

  >img {
    width: 256px;
    height: 256px;
    border-radius: 8px;
    object-fit: contain;
  }
}

.right {
  flex: 1;
  margin-left: 24px;

  .detail {
    :deep(.ant-popover) {
      width: 400px;

      .popconfirm-title {
        >p {
          font-weight: 400;
          font-size: 14px;
          color: var(--dark-1);
          letter-spacing: 0.4px;
          line-height: 22px;

          &:first-child {
            font-weight: 500;
            margin-bottom: 6px;
          }
        }
      }

      .ant-popover-inner {
        padding: 15px;
      }
    }

    .title {
      >a {
        color: var(--dark-1);
        background: var(--bg-gray-8);
        border-radius: 8px;
        padding: 0 8px;
        height: 28px;
        display: inline-flex;
        line-height: 28px;
        font-size: 16px;
        font-weight: 500;
        align-items: center;
        text-decoration: underline;

        &:hover {
          background: var(--bg-gray-5);
        }

        >.icon {
          margin-left: 8px;
          font-size: 20px;
        }
      }

      &.deals-section .title-head>p {
        margin-top: 40px;
      }

      .title-head {
        display: flex;
        justify-content: space-between;

        >p {
          flex: 1;
          font-weight: 600;
          font-size: 16px;
          color: var(--dark-1);
          letter-spacing: 0.5px;
          line-height: 22px;
          margin-top: 6px;
          margin-bottom: 16px;

          &.sub-title {
            display: flex;
            font-size: 20px;
            align-items: center;
            justify-content: space-between;

            >.add-deal {
              display: flex;
              font-size: 14px;
            }

            .iconfont {
              height: 22px;
            }
          }
        }

        .operation:deep {
          position: relative;

          // align-self: center;
          >button {
            padding: 0 !important;
            width: 32px;
            font-size: 20px;
          }

          // > span {
          //   margin-left: 12px;
          //   width: 32px;
          //   > button {
          //     padding: 0 !important;
          //     width: 32px;
          //     font-size: 20px;
          //     > svg {
          //       width: 20px;
          //       height: 20px;
          //       vertical-align: -5px;
          //     }
          //   }
          // }

          >span {
            cursor: pointer;
            padding: 0 12px;
            background: #ffffff;
            border: 1px solid #ccc7d9;
            box-shadow: 0 1px 0 0 #0f06290d;
            border-radius: 8px;
            margin-left: 10px;
          }

          .inactive-btn {
            position: absolute;
            right: 0;
            display: none;
            // display: flex;
            padding-top: 10px;

            .deactivate {
              width: 180px;
              text-align: center;
              cursor: pointer;
              padding: 4px 12px;
              background: #ffffff;
              border: 1px solid #ccc7d9;
              box-shadow: 0 1px 0 0 #0f06290d;
              border-radius: 8px;
              margin-left: 10px;
            }
          }

          &:hover {
            .inactive-btn {
              display: flex;
            }
          }

          .ant-dropdown-menu-item {
            color: var(--dark-1);
          }

          .ant-btn:not(:disabled):focus-visible {
            outline: unset;
          }
        }
      }

      >p {
        font-weight: 400;
        font-size: 13px;
        color: var(--dark-1);
        letter-spacing: 0.3px;
        line-height: 18px;
        margin-top: 6px;
        text-decoration: underline;

        >a {
          color: var(--dark-1);
        }
      }
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
      position: relative;

      &::after {
        left: 50%;
        top: 10px;
        content: '';
        position: absolute;
        width: 0;
        height: 143px;
        border-right: 1px solid #e8e5ed;
      }

      .item {
        width: 50%;
        display: flex;
        justify-content: space-between;

        &:nth-child(2n) {
          padding-left: 50px;
        }

        &:nth-child(2n + 1) {
          padding-right: 50px;
        }

        >span {
          line-height: 44px;
          font-size: 14px;
          align-self: center;
          color: var(--dark-3);
          font-weight: 500;

          &:last-child {
            font-weight: 600;
            color: var(--dark-1);
          }

          >a {
            color: var(--dark-1);
            text-decoration: underline;
          }
        }
      }

      .edit-commission {
        display: flex;

        >button {
          margin-left: 10px;
        }

        .commission {
          align-self: center;

          .text {
            padding-right: 12px;
            font-weight: 600;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              right: 0;
              top: 2px;
              width: 0;
              height: 18px;
              border-right: 1px solid var(--border-5);
            }
          }

          >span {
            &:last-child {
              margin-left: 10px;
            }
          }
        }

        .history {
          border: none;
          padding: 0;
          line-height: 32px;
          display: flex;
          box-shadow: none;
          color: var(--dark-3);

          .iconfont {
            height: 26px;
            font-size: 20px;
            vertical-align: middle;
          }

          :deep(svg) {
            width: 20px;
            height: 20px;
            fill: var(--dark-3);
          }
        }
      }
    }
  }
}

.content-bottom {
  .creators {
    margin-top: 20px;
    background: #ffffff;
    border: 2px solid #e8e5ed;
    border-radius: 12px;

    .content-bottom-wrap {
      padding: 16px;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 85px 0 85px 0;
      background: #f8f7fc;
      border-radius: 8px;
      text-align: center;

      >svg {
        width: 96px;
        height: 96px;
        align-self: center;
      }

      >p {
        margin-top: 12px;
        opacity: 0.7;
        font-weight: 500;
        font-size: 18px;
        color: #0f0629;
        letter-spacing: 0.4px;
        line-height: 25px;
      }
    }

    >p {
      padding: 12px 16px;
      font-weight: 500;
      font-size: 20px;
      color: var(--dark-1);
      letter-spacing: 0.5px;
      line-height: 28px;
      border-bottom: 1px solid #e8e5ed;
    }
  }
}

.seller-product-variations {
  padding: 16px;
  margin-top: 20px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: var(--section-box-shadow-6);
}
</style>